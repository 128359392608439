.carousel-inner img {
    width: 100%;
    height: 700px;
  }

  .carousel-caption{
      position: absolute !important;
      top: 30%;
    
  }

  .overlay-banner{
     font-size: 70px;
     text-transform: uppercase;
     font-weight: 550;
  }
  .overlay-banner3{
    font-size: 62px;
    text-transform: uppercase;
    font-weight: 550;
 }
  .overlay-banner2{
      background-color: rgba(255, 115, 0, 0.644);
      padding: 9px;
      text-transform: uppercase;

  }
  @media screen and (max-width:800px) {
    .overlay-banner3{
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 550;
     }
    .carousel-caption{
        position: absolute !important;
        top:30%;
    }
    .overlay-banner{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 550;
     }
    .carousel-inner img {
        width: 100%;
        margin-top: 40px;
        height: 300px;
      }
  }