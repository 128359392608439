h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
/* #Navigation
================================================== */

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 28px;
  width: auto;
  display: block;
  filter: brightness(10%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-link {
  font-size: 13px;
  color: gray !important;
  font-weight: 520;
  transition: all 200ms linear;
}
.nav-item:hover .nav-link {
  color: #ffffff !important;
  background-color: #bd1626;
  border-radius: 10px;
  padding: 7px !important;
}
.nav-item.active .nav-link {
  color: #777 !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";

  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}

/* #Primary style
================================================== */

.bg-light {
  background-color: #f7f8fa !important;
  transition: all 200ms linear;
}
.section {
  position: relative;
  width: 100%;
  display: block;
}
.full-height {
  height: 100vh;
}
.over-hide {
  overflow: hidden;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}

body.hero-anime p {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1700ms;
}
h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}
body.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}
body.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}
body.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}
body.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}
body.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}
body.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}
h1 span:nth-child(1) {
  transition-delay: 1000ms;
}
h1 span:nth-child(2) {
  transition-delay: 700ms;
}
h1 span:nth-child(3) {
  transition-delay: 900ms;
}
h1 span:nth-child(4) {
  transition-delay: 800ms;
}
h1 span:nth-child(5) {
  transition-delay: 1000ms;
}
h1 span:nth-child(6) {
  transition-delay: 700ms;
}
h1 span:nth-child(7) {
  transition-delay: 900ms;
}
h1 span:nth-child(8) {
  transition-delay: 800ms;
}
h1 span:nth-child(9) {
  transition-delay: 600ms;
}
h1 span:nth-child(10) {
  transition-delay: 700ms;
}
body.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}
body.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}
body.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}
body.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}
h1 span:nth-child(11) {
  transition-delay: 1300ms;
}
h1 span:nth-child(12) {
  transition-delay: 1500ms;
}
h1 span:nth-child(13) {
  transition-delay: 1400ms;
}
h1 span:nth-child(14) {
  transition-delay: 1200ms;
}
h1 span:nth-child(15) {
  transition-delay: 1450ms;
}
#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #bd1626;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}
body.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}
#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}
.switched {
  border-color: #000 !important;
  background: #bd1626 !important;
}
.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}
.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff !important;
  background-color: #bd1626;
}

body.dark {
  color: #fff;
  background-color: #1f2029;
}
body.dark .navbar-brand img {
  filter: brightness(100%);
}
body.dark h1 {
  color: #fff;
}
body.dark h1 span {
  transition-delay: 0ms !important;
}
body.dark p {
  color: #fff;
  transition-delay: 0ms !important;
}
body.dark .bg-light {
  background-color: #14151a !important;
}
body.dark .start-header {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}
body.dark .start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
body.dark .nav-link {
  color: #fff !important;
}
body.dark .nav-item.active .nav-link {
  color: #999 !important;
}
body.dark .dropdown-menu {
  color: #fff;
  background-color: #1f2029;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}
body.dark .dropdown-item {
  color: #fff;
}
body.dark .navbar-light .navbar-toggler-icon {
  border-bottom: 1px solid #bd1626;
}
body.dark .navbar-light .navbar-toggler-icon:after,
body.dark .navbar-light .navbar-toggler-icon:before {
  background-color: #fff;
}
body.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* #Media
================================================== */
.c_icons {
  margin-left: 30px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }
  .c_icons {
    margin-left: 90px;
  }
  .nav-item:after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
  }
  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  body.dark .nav-item::before {
    background-color: #fff;
  }
  body.dark .dropdown-toggle::after {
    background-color: #fff;
  }
  body.dark .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}


.gallery {
  width:100%;
  margin-top: 20px;
  height: auto;
}
/* #Link to page
================================================== */

.logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
  filter: brightness(10%);
  transition: all 250ms linear;
}

.date {
  background-color: blueviolet;
  float: left;
  padding: 5px 20px;

  margin-right: 20px;
}

.calendar {
  font-size: 50px;
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
}

.month {
  color: white;
  font-weight: bold;
  text-align: center;
}

.paragraph {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

@media (max-width: 800px) {
  .paragraph {
    font-size: 22px;
  }

  .calendar {
    font-size: 35px;
    font-weight: bold;
    color: white;
    margin-bottom: 0px;
  }

  .date {
    background-color: blueviolet;
    float: left;
    padding: 4px 16px;
    height: 100px;
    margin-right: 20px;
  }
}

.container-bg {
  background-color: #eceeee;
  padding: 20px;
}

.social {
  background-color: white;
  text-align: center;
  padding: 20px;
}

.fb_section {
  width: 100%;
  height: 100%;
  margin-left: 0%;
}

.img-fluid {
  height: 110px;
}
.iframsDimension{
  width: 340px;
  height: 500px !important;
}
@media screen and (max-width:800px) {
  .social{
    width: 300px;
  }
  .iframsDimension{
    width: 250px;
    height: 400px !important;
  }
}
.bg {
  background-color: lightgrey;
  padding: 40px;
  margin-top: 30px;
}

.pic {
  margin: 40px;
  margin-bottom: 10px;
}

.para {
  margin: 15px;
}

.grid {
  text-align: center;
  background-color: white;
  border: 1px solid grey;
  width: 100%;
  height: 450px;
}

.link a {
  text-decoration: none;
  color: #16a085;
}

.link a:hover {
  color: #00745c;
}

@media screen and (max-width: 800px) {
  .bg {
    background-color: grey;
    padding: 20px;
    margin-top: 10px;
  }

  .grid {
    margin-bottom: 10px;
    height: auto;
  }

  .fb_section {
    margin-left: 0;
    width: 100%;
  }
}

/* contact -us */

input:focus,
textarea:focus,
keygen:focus,
select:focus {
  outline: none;
}
::-moz-placeholder {
  color: #666;
  font-weight: 300;
  opacity: 1;
}

::-webkit-input-placeholder {
  color: #666;
  font-weight: 300;
}

/* Contact Form Styling */

.textcenter {
  text-align: center;
}
.section1 {
  text-align: center;
  display: table;
  width: 100%;
}
.section1 .shtext {
  display: block;
  margin-top: 20px;
}
.section1 .seperator {
  border-bottom: 1px solid #bd1626;
  width: 35px;
  display: inline-block;
  margin: 20px;
}

.section1 h1 {
  font-size: 40px;
  color: rgb(27, 118, 209);
  font-weight: normal;
}

.section2 {
  width: 1200px;
  margin: 25px auto;
}
.section2 .col2 {
  width: 48.71%;
}
.section2 .col2.first {
  float: left;
}
.section2 .col2.last {
  float: right;
}
.section2 .col2.column2 {
  padding: 0 30px;
}
.section2 span.collig {
  color: #a2a2a2;
  margin-right: 10px;
  display: inline-block;
}
.section2 .sec2addr {
  display: block;
  line-height: 26px;
}
.section2 .sec2addr p:first-child {
  margin-bottom: 10px;
}
.section2 .sec2contactform input[type="text"],
.section2 .sec2contactform input[type="email"],
.section2 .sec2contactform textarea {
  padding: 18px;
  border: 0;
  background: #ededed;
  margin: 7px 0;
}
.section2 .sec2contactform textarea {
  width: 100%;
  display: block;
  color: #666;
  resize: none;
}
.section2 .sec2contactform input[type="submit"] {
  padding: 15px 40px;
  color: #fff;
  border: 0;
  background: rgb(189, 28, 44);
  font-size: 16px;
  text-transform: uppercase;
  margin: 7px 0;
  cursor: pointer;
}
.section2 .sec2contactform h3 {
  font-weight: normal;
  margin: 20px 0;
  margin-top: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 19px;
  color: rgb(189, 28, 44);
}

/* @media querries */

@media only screen and (max-width: 1266px) {
  .section2 {
    width: 100%;
  }
}
@media only screen and (max-width: 960px) {
  .section2 .col2 {
    width: 100%;
    display: block;
  }
  .section2 .col2.first {
    margin-bottom: 10px;
  }
  .section2 .col2.column2 {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .section2 .sec2addr {
    font-size: 14px;
  }
  .section2 .sec2contactform h3 {
    font-size: 16px;
  }
  .section2 .sec2contactform input[type="text"],
  .section2 .sec2contactform input[type="email"],
  .section2 .sec2contactform textarea {
    padding: 10px;
    margin: 3px 0;
  }
  .section2 .sec2contactform input[type="submit"] {
    padding: 10px 30px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 420px) {
  .section1 h1 {
    font-size: 28px;
  }
}

.maps {
  width: 100%;
  height: 650px;
}

.youthImg {
  width: 100%;
  height: 450px;
}
@media screen and (max-width: 600px) {
  .maps {
    width: 100%;
  }

  .youthImg {
    width: 100%;
    height: auto;
  }
}
.name {
  color: #bd1c2c !important;
  font-weight: 500;
  font-size: 24px;
}
.text-theme {
  color: #bd1c2c !important;
  font-weight: 550;
}
.thumb img {
  width: 165px;
  max-height: 215px;
}

@media screen and (max-width: 600px) {
  .thumb img {
    width: 100%;
    max-height: 215px;
    /* margin-bottom: 20px; */
  }
 
}
.line-bottom {
  position: relative;
  margin-bottom: 30px;
  font-weight: 600;
  padding-bottom: 10px;

  color: #bd1c2c;
}
a{
  color: gray !important;
  text-decoration: none !important;
}